import {
	addFavorite,
	removeFavorite,
} from '@cocoonspace/sdk-js/domains/profile/profile'
import { useMutation } from '@tanstack/react-query'

export const useSpaceFavoriteUpdate = () => {
	return useMutation({
		mutationFn: ({ id, favorite }: { id: string; favorite?: boolean }) => {
			return favorite ? removeFavorite(id) : addFavorite(id)
		},
		onSuccess: () => {},
	})
}
